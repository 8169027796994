<template>
	<div>
		<span>{{itemData.title}}</span>
	</div>
</template>

<script>
  export default {
    name: "DropdownItem",
    props:{
      itemData:{
        type: Object
      }
    }
  }
</script>

<style scoped>

</style>